$(window).on('resize', function() {
  backCalc();
});

$(document).on('scroll', function() {
  bussinessUnitHover();
  navStyles();
});

/* Lleva cualquier #hash de la url a un ID existente en la pagina con una trasicion suave */
function anchorGoToHash() {
  $('a[href*="#"]:not([href="#"])').click(function (e) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 500);
        return false;
      }
    }
  });
}

var swiper = new Swiper(".hero__swiper", {
  slidesPerView: 1,
  loop: true,
  effect: "fade",
  watchOverflow: true,
  allowTouchMove: false,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
});

function navStyles(){
  var st = $(window).scrollTop(),
      heroHei = $('.hero').height(),
      navHei = $('.navigation').height(),
      heroHei = heroHei - navHei,
      navTop = $('.navigation').offset().top;

  if(st >= navTop){
    $('.navigation').addClass('is-fixed');
  }
  if(st <= heroHei){
    $('.navigation').removeClass('is-fixed');
  }
}

function backCalc(){
  var sectionPadding = parseInt($('.section_values').css('padding-top')),
      valueImgSize = $('.value__img').height();

  $('.section').css('--sectionTop', sectionPadding + 'px');
  $('.section').css('--valueTop', valueImgSize + 'px');
}

function bussinessUnitHover(){
  var st = $(window).scrollTop(),
      screenWidth = $(window).width(),
      itemData = [];

      $('.bussinessUnit').each(function(){
        var itemTop = $(this).offset().top,
            itemBottom = itemTop + $(this).height(),
            itemName = $(this).data('unit');
  
        itemData.push({unit: itemName, top: itemTop, bottom: itemBottom});
      })
  
  if(screenWidth > 768){
    $('.bussinessUnit').mouseenter(function(){
      $(this).find('.bussinessUnit__overlay').removeClass('mouseOut').addClass('mouseIn');
    })
    $('.bussinessUnit .cta').focusin(function(){
      $(this).parent().parent().removeClass('mouseOut').addClass('mouseIn');
    })
    $('.bussinessUnit').mouseleave(function(){
      $(this).find('.bussinessUnit__overlay').removeClass('mouseIn').addClass('mouseOut');
    })
    $('.bussinessUnit .cta').blur(function(){
      $(this).parent().parent().removeClass('mouseIn').addClass('mouseOut');
    })
  } else {
    for(var i = 0; i < itemData.length; ++i){

      if(st >= itemData[i].top && st <= itemData[i].bottom){
        if(!$('.bussinessUnit[data-unit="'+itemData[i].unit+'"] .bussinessUnit__overlay').hasClass('mouseIn')){
          $('.bussinessUnit[data-unit="'+itemData[i].unit+'"] .bussinessUnit__overlay').addClass('mouseIn');
        }
      }
    }
  }
  
}

function formStyles(){

  // Labels states
  $('div.form__input').each(function(){
    $(this).addClass('is-label-down');
  });

  $('div.form__input input:not([type="file"]), div.form__input textarea').each(function() {
    var me = $(this),
        parent = me.parent();

    if( me.val() == '' || me.val() == undefined ) {
      parent.addClass('is-label-down');
    } else {
      parent.removeClass('is-label-down');
    }
  });

  $('.form__input').find('input:not([type="file"]), textarea').on('keyup blur focus', function(e){

      // Cache our selectors
      var me = $(this),
          parent = me.parent();

      // Add or remove classes
      if (e.type == 'keyup') {
        if( me.val() == '' ) {
          parent.addClass('is-label-down');
        } else {
          parent.removeClass('is-label-down is-wrong');
        }
      } else if (e.type == 'blur') {
        if( me.val() == '' ) {
          parent.addClass('is-label-down');
        }
        else {
          parent.removeClass('is-label-down is-wrong');
        }
      } else if (e.type == 'focus') {
        parent.removeClass('is-label-down is-wrong');
      }
  });
}

// Email validation
function valemail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function fieldValidation() {
  var isValid = false;

  $('.form__input input, .form__input textarea').each(function () {
    var required = $(this).prop('required'),
        val = $.trim($(this).val()),
        type = $(this).attr('type'),
        parent = $(this).parent();

    if ( required && val === '') {
      parent.addClass('is-wrong');
    } else if ( type ==  'email' && required && val !== '' ) {
      if (valemail(val)) {
        parent.removeClass('is-wrong');
      } else {
        parent.addClass('is-wrong');
      }
    } else {
      parent.removeClass('is-wrong');
    }
  });

  if (!$('.form__input').hasClass('is-wrong')) {
    isValid = true;
  }

  return isValid;
}

function formValidation() {

  $('#submit').on('click', function(event){
    event.preventDefault();
    
    if ( fieldValidation() ) {      
      var data = new FormData(),
          fields = ['bussiness-unit', 'phone', 'email', 'message'],
          url = './assets/php/sendEmail.php';
    
      for (var i = 0; i < fields.length; i++) {
        data.append(fields[i], $("#" + fields[i]).val());
      }

      $.ajax({
        url: url,
        data: data,
        type: 'POST',
        contentType: false,
        processData: false,
        beforeSend: function() { 
          $('#submit').addClass('cta_disabled');
          $('#submit i').removeClass('icon-angle-right').addClass('icon-spin animate-spin');
          $('#validation').hide().text(' ');
        },
        success: function(response) {
          if(response === 'OK.'){
            $('.form__input input, .form__input textarea').val('').parent().addClass('is-label-down');
            $('#validation').show().addClass('is-ok').text('Su mensaje fue enviado exitosamente.');
            setTimeout(function(){
              $('#validation').hide();
            }, 5000);
          } else {
            $('#validation').show().removeClass('is-ok').text('Algo salió mal. Por favor intente otra vez.');
          }
        },
        complete: function() { 
          $('#submit').removeClass('cta_disabled');
          $('#submit i').removeClass('icon-spin animate-spin').addClass('icon-angle-right');
        }
      });
    } else {
      $('#validation').show().removeClass('is-ok').text('Uno o más campos tienen un error. Por favor, revíselos e inténtenlo de nuevo.');
    }
  })
  return false;
}

function jobformValidation() {
  $('#submit-job').on('click', function(event){
    event.preventDefault();

    var data = new FormData(),
        inputFile = $('#file');

    if ( inputFile.val() !== '' ){
      data.append('file', inputFile[0].files[0]);

      $.ajax({
        url: './assets/php/sendEmail_job.php',
        data: data,
        type: 'POST',
        contentType: false,
        processData: false,
        beforeSend: function() { 
          $('#submit-job').addClass('cta_disabled');
          $('#submit-job i').removeClass('icon-angle-right').addClass('icon-spin animate-spin');
        },
        success: function (response) {
          if (response === 'OK.') {
            inputFile.parent().removeClass('is-wrong');
            inputFile.val('');
            $('#validation-job').show().addClass('is-ok').html('Recibimos sus datos. Muchas gracias.');
            setTimeout(function(){
              $('#validation-job').hide();
            }, 5000);

          } else if (response === 'Unknown/not permitted file type') {

            $('#validation-job').show().removeClass('is-ok').html('Archivo no permitido.');
            inputFile.parent().addClass('is-wrong');

          } else if (response === 'Unknown/file size not allowed') {

            $('#validation-job').show().removeClass('is-ok').html('Archivo demasiado grande.');
            inputFile.parent().addClass('is-wrong');

          } else {

            $('#validation-job').show().removeClass('is-ok').html('Algo salió mal. Por favor intenta otra vez.');
            console.log('Error');

          }

        },
        complete: function(){
          $('#submit-job').removeClass('cta_disabled');
          $('#submit-job i').removeClass('icon-spin animate-spin').addClass('icon-angle-right');
        }
      });
    } else {
      inputFile.parent().addClass('is-wrong');
      $('#validation-job').show().removeClass('is-ok').text('Adjunte un documento.');
    }
  })
  return false;
}