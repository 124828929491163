$(document).ready(function() {

  /* Frontend */
  anchorGoToHash();
  backCalc();
  bussinessUnitHover();
  formStyles();
  navStyles();
  formValidation();
  jobformValidation();

});
